<template>
  <!--
    Used to add Video Recorder Configurations
  -->
  <div class="videoRecorderConfigurationAdd">
    <h4 class="m-0 mt-3">
      {{ $t('addVideoRecorderConfigurationComp.config') }}
    </h4>
    <hr class="m-0 mb-3">
    <div class="form-group m-form__group row mb-2">
      <label class="col-4 col-form-label">{{ $t('camera') }}</label>
      <div class="col-8">
        <select
          v-model="videoRecorderConfig.cameraId"
          :class="['form-control', { 'is-invalid': this.$validator.errors.has('Camera') }]"
        >
          <option
            v-for="(camera, index) in cameras"
            :key="`cutConfigListItemAdd_Option${ index }`"
            :value="camera.id"
          >
            {{ camera.id }} - {{ camera.ipAddress }}
          </option>
        </select>
        <span
          v-show="errors.has('Camera')"
          class="badge badge-danger"
        >{{ errors.first('Camera') }}</span>
      </div>
    </div>

    <hr class="m-0 mb-3">
    <div class="form-group m-form__group row mb-2">
      <label class="col-4 col-form-label">{{ $t('orderId') }}</label>
      <div class="col-8">
        <input
          v-model="
            videoRecorderConfig.orderId"
          :class="['form-control', { 'is-invalid': this.$validator.errors.has('OrderId') }]"
          type="number"
          min="0"
          max="100"
          step="1"
        >
        <span
          v-show="errors.has('OrderId')"
          class="badge badge-danger"
        >{{ errors.first('OrderId') }}</span>
      </div>
    </div>

    <hr class="m-0 mb-3">
    <div class="form-group row mb-2">
      <label class="col-12 col-sm-3 col-form-label">{{ $t('previewCamera') }}</label> 
      <div class="col-12 col-sm-9">
        <toggle-button
          v-model="videoRecorderConfig.previewCamera"
          :labels="{ checked: $t('true'), unchecked: $t('false') }"
          :class="['m-0 ml-4 mt-2', { 'is-invalid': this.$validator.errors.has('PreviewCamera') }]"
          :width="70"
          :height="30"
          :font-size="12"
          @change="changeOrderId"
        />
      </div>
    </div>
    <span
      v-show="errors.has('PreviewCamera')"
      class="badge badge-danger"
    >{{ errors.first('PreviewCamera') }}</span>

    <hr class="m-0 mb-3">
    <div class="form-group row mb-2">
      <label class="col-12 col-sm-3 col-form-label">{{ $t('active') }}</label> 
      <div class="col-12 col-sm-9">
        <toggle-button
          v-model="videoRecorderConfig.active"
          :labels="{ checked: $t('true'), unchecked: $t('false') }"
          :class="['m-0 ml-4 mt-2', { 'is-invalid': this.$validator.errors.has('Active') }]"
          :width="70"
          :height="30"
          :font-size="12"
        />
      </div>
    </div>
    <span
      v-show="errors.has('Active')"
      class="badge badge-danger"
    >{{ errors.first('Active') }}</span>

    <span
      v-if="!videoRecorderConfig.previewCamera"
    >
      <hr class="m-0 mb-3">
      <div class="form-group row mb-2">
        <label class="col-12 col-sm-3 col-form-label">ActiveOnStart</label> 
        <div class="col-12 col-sm-9">
          <toggle-button
            v-model="videoRecorderConfig.activeOnStart"
            :labels="{ checked: $t('true'), unchecked: $t('false') }"
            :class="['m-0 ml-4 mt-2', { 'is-invalid': this.$validator.errors.has('ActiveOnStart') }]"
            :width="70"
            :height="30"
            :font-size="12"
          />
        </div>
      </div>
      <span
        v-show="errors.has('ActiveOnStart')"
        class="badge badge-danger"
      >{{ errors.first('ActiveOnStart') }}</span>
    </span>

    <button
      class="btn btn-primary mt-2 float-right"
      @click="addVideoRecorderConfig"
    >
      <font-awesome-icon
        class="mr-1"
        icon="upload"
      />
      <span>
        {{ $t('add') }}
      </span>
    </button>
    <div class="clearfix" />
  </div>
</template>

<script>
import { errorMixin } from '@/mixins/errorMixin.js';

export default {
  name: "AddVideoRecorderConfiguration",
  mixins: [
    errorMixin
  ],
  props: {
    installationId: {
      type: String,
      required: true
    },
    laneNumber: {
      type: String,
      required: false,
      default () {
        return null;
      }
    }
  },
  data () {
    return {
      cameras: [],
      loading: true,
      videoRecorderConfig: {
        cameraId: 0,
        previewCamera: false,
        active: true
      }
    }
  },
  created () {
    this.getCameraConfigs();
  },
  methods: {
    addVideoRecorderConfig () {
      if(!this.videoRecorderConfig.orderId || !this.videoRecorderConfig.orderId)
      {
          this.$snotify.error('Please insert a valid Order Id');
          return;
      }
      let url = `/Config/AddNewVideoRecorder?installationId=${ this.installationId }`; 
      if (this.laneNumber)
      {
        url += `&laneNumber=${ this.laneNumber }`;
      }
      this.axios.post(url, this.videoRecorderConfig)
        .then((response) => {
          if (response.status == 204) {
            this.$emit('hide');
            this.$emit('setReloadButton', true);
            this.$snotify.error(this.$t('configReload.failedReload'));
          } else {
            this.$emit('setReloadButton', false);
            if (response.status != 200) {
              return;
            }
          }
          
          this.$emit('reloadConfig');
          this.$snotify.success(this.$t('videoDirectorConfigFormComponent.videoRecorderWasAdded'));
        })
        .catch(() => {
          this.$snotify.danger(this.$t('videoDirectorConfigFormComponent.videoRecorderWasNotAdded'));
        });
    },
    getCameraConfigs () { 
      let url = `/Config/GetCameraManagementConfig?installationId=${ this.installationId }`; 
      if (this.laneNumber)
      {
        url += `&laneNumber=${ this.laneNumber }`;
      }
      this.axios.get(url)
        .then((response) => {
          if (!response || !response.data) {
            return;
          }
          this.cameras = response.data.cameraConfigs; 
        })
        .finally(() => {
          if (this.cameras != null) {
            this.loading = false;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    changeOrderId () {
      if(this.videoRecorderConfig.previewCamera) {
        this.videoRecorderConfig.orderId = this.videoRecorderConfig.orderId ? Number(this.videoRecorderConfig.orderId) + 100 : 101;
      } else {
        this.videoRecorderConfig.orderId = this.videoRecorderConfig.orderId ? Number(this.videoRecorderConfig.orderId) - 100 : 0;
      }
    },
  }
}
</script>
